$breakpoint-sm: 767px;
$stravaOrange: #ea5a29;
$stravaLightContrast: #fff;

a, a:hover{
  color: $stravaOrange;
}
.strava-orange{
  background-color: $stravaOrange;
}
.small{
  font-size: 13px;
}
.condensed{
  height: 100px;
}

.connection{
  height: 20vh;
}

body{
  background-color: #282c34;
  color: $stravaLightContrast;
}

.alert-orange{
  color: $stravaOrange;
  background-color: #fff;
  border-color: $stravaOrange;
}

.App {
  background-color: #282c34;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.error{
  margin-bottom: 10px;
  font-size: 14px;
  color: red;
}

.logo{
  width: 100px;
  height: 100px;
  margin: 30px;
}
header h5{
  max-width: 600px;
}
.big-btn{
  background-color: $stravaOrange;
  color: $stravaLightContrast;
  height: 60px;
  width: 300px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.queryType{
  display: block;
}
.lastChecked{
  display: block;
  margin-bottom: 20px;
}

.poweredBy {
  width: 200px;
}

.section-title{
  display:block;
  font-size: 20px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $stravaOrange;
}
.section-subtitle{
  padding-top: 20px;
}

.legend-status{
  font-family: "Lobster";
  font-size: 4em;
  margin-bottom: 30px;
}
.total{
  font-size: 200%;
  font-weight: bold;
  margin-bottom: 20px;
  display: block;
  background-color: $stravaLightContrast;
  color: $stravaOrange;
  width: 300px;
  padding: 20px;
  border-radius: 50px;
  &.right { float: right; }
  @media (max-width: $breakpoint-sm) {
    &.right{ float: none; }
    display: inline-block;
  }
  .total-subheader{
    display: block;
    font-size: .5em;
  }
}

.top-activities{
  list-style-type: none;
  font-size: 1.5em;
  text-align: left;
  padding: 0px;
  margin: 0 auto;
  @media (max-width: $breakpoint-sm){
    font-size: 1em;
  }
  li{
    border: 1px solid $stravaOrange;
    border-radius: 5px;
    padding: 10px;

    margin-left: 0%;
    margin-bottom: 5px;
    
    .activity-name{
      display: block;
      margin-left: 0px;
      font-weight: bold;
      
    }
    .activity-kudos{
      //display: block;
      
      //padding: .3em;
      /*background-color: $stravaLightContrast;
      color: $stravaOrange;
      border-radius: 12px;*/
      float: right;
    }
  }
}

.athlete{
  word-break: break-all;
  .athlete-profile{
    display:block;
    border-radius: 60px;
    height: 120px;
    width: 120px;
    margin: 0 auto;
    overflow:hidden;
    text-align: right;
  }
  .athlete-info{
    display: block;
    padding-top: 30px;
    padding-bottom: 20px;
    
    .athlete-name{
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    .athlete-details{
      display: block;
      font-size: 14px;
    }
  }
}
.progress{
  height: 30px;
}
.progress-bar{
  background-color: $stravaOrange;
}
.loading{
  width: 300px;
  margin-bottom: 20px;
  margin: 0 auto;
}
.results{
  margin-top: 30px;
  color: $stravaLightContrast;

  .fans{
    width: 100%;
    .fan-group.podium {
      .fan{
        .activity{
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .icon{
          margin-right: 10px;
        }

      }
      .place{
        position: relative;
        top: 24px;
        font-size: 400%;
        font-weight: bold;
        .place-number{
          font-size: .4em;
          color: $stravaOrange;
          position: relative;
          left: -65px;
          top: -40px;
          &.place-1{
            top: -20px;
          }
        }
      }
      font-size: 150%;
      .fan-col:first-child{ 
        .fan {
          @media (max-width: $breakpoint-sm){
            width: 100%;
          }
          width: 40%;
          margin-bottom: 50px;
          height: 135px;
          .kudos{
            font-size: 120%;
            
          }
        }
      }
      .fan-col:nth-child(2), .fan-col:nth-child(3){
        .fan{
          @media (max-width: $breakpoint-sm){
            width: 100%;
          }
          width: 80%;
          margin-bottom: 50px;
          height: 120px;
          .title{
            font-size: 60%;
          }
          .kudos{
            font-size: 100%;
            
          }
        }
      }
    }
    .fan-group.others{
      .fan{
        border-radius: 65px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 130px;
        min-height: 230px;
        //height: 130px;
        .icon{
          text-align: center;
          display:block;
          font-size: 2.1em;
          position: relative;
          top: -6px;
        }
        .name{
          font-size: .8em;
          display: block;
          position: relative;
          top: 55px;
          text-align: center;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          //.text{
            
          //}
        }
        .right{
          display: block;
          float: none;
          margin-top:0px;
          position: relative;
          top: -15px;
          text-align: center;
          .place{
            width: 50px;
            height: 50px;
            border-radius: 25px;
            background-color: $stravaLightContrast;
            color: $stravaOrange;
            display: inline-block;
            position: relative;
            top: -11px;
            margin-right: 10px;
            .number{
              margin-top: 8px;
              display: inline-block;
            }
            .text{
              display: block;
              font-size: .5em;
              margin-top: -8%;
            }
          }
          .kudos{
            display: inline-block;
            margin-top: 0px;
          }
        }
        .date{
          margin-top: 20px;
          padding-bottom: 30px;
          position: relative;
          top: 15px;
          text-align: center;
          
          display: block;
          margin: 0 auto;
        }
        .activity{
          width: 75px;
          position: relative;
          top: -10px;
          text-align: center;
          margin: 0 auto;
        }
        @media (max-width: $breakpoint-sm){
          //transform: none !important;
        }
        &.first-tier{
          color: $stravaLightContrast;
          background-color: $stravaOrange;
          .right{
            .place{
              background-color: $stravaLightContrast;
              color:$stravaOrange;
            }
          }
          .activity{
            a{ 
              color: $stravaLightContrast;
            }
          }
        }
        &.second-tier{
          transform: scale(0.9);
          color: $stravaOrange;
          background-color: #eee;
          border: 4px solid $stravaOrange;
          .right{
            .place{
              background-color: $stravaOrange;
              color:$stravaLightContrast;
            }
          }
          .activity{
            a{ 
              color: $stravaOrange;
            }
          }
        }
        &.third-tier{
          transform: scale(0.8);
          .icon{
            color: $stravaOrange;
          }
          
          color: #333;
          background-color: #ccc;
          border: 4px solid $stravaOrange;
          a{
            color: $stravaOrange;
          }
          .right{
            .place{
              background-color: $stravaLightContrast;
              color:#444;
            }
          }
        }
        &.fourth-tier{
          transform: scale(0.7);
          color: #333;
          background-color: #ccc;
          a{
            color: #333;
          }
          border-radius: 10px;
          .right{
            .place{
              background-color: #aaa;
              color:#444;
            }
          }
        }
        &.fifth-tier{
          transform: scale(0.6);
          color: #999;
          background-color: #111;
         
          border-radius: 10px;
          .right{
            .place{
              background-color: #999;
              color:#333;
            }
          }
        }
      }
    }

    .fan{
      width: 100%;
      display: inline-block;
      border-radius: 5px;
      left: 0px;
      margin-bottom: 10px;
      list-style-type: none;
      text-align: left;
      padding: 5px;
      background-color: $stravaOrange;
      .title{
        display:block;
        text-align: center;
        background-color: $stravaLightContrast;
        border-radius: 5px;
        color: $stravaOrange;
      }
      .right{
        float:right;
      }
      //.place{
        
      //}
      .icon{
        font-size: 150%;
      }
      //.name{

      //}
      .kudos{
        position: relative;
        top: -5px;
        .count{
          font-size: 130%;
          font-weight: bold;
        }
        .text{
          display:block;
          margin-top: -8%;
          text-align: center;
          font-size: 50%;
          position: relative;
          top: -5px;
        }
      }
      .date{
        display: block;
        font-size: 50%;
      }
      .activity{
        display:block;
        font-size: 50%;
        font-weight: bold;
        a{ 
          color: $stravaLightContrast;
        }
        a:hover{
          text-decoration: underline;
        }
      }
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

header {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //font-size: calc(10px + 2vmin);
  color: white;
}
header.small{
  min-height: 30vh;
}

section.content{
  min-height: 60vh;
}

footer{
  min-height: 200px;
  .copyright{
    display: block;
    color: $stravaLightContrast;
    font-size: 50%;
  }
  .disclaimer{
    margin-top: 10px;
    display: block;
    color: #aaa;
    font-size: 50%;
  }
}

.App-link {
  color: #61dafb;
}

.animated {
  animation-duration: 2.5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
.bounce {
  animation-name: bounce;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 650px) {
  header{
    h1{
      font-size: 24px;
    }
    h5{
      font-size: 12px;
    }
    .logo{
      height: 50px;
      width: 50px;
    }
    min-height: 30vh;

  }
}
